<template>
  <!-- 编辑菜单页面 -->
  <div class="positionT0L0">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 3 }" :wrapper-col="{ span: 6 }">
      <a-form-model-item ref="title" label="菜单名称" prop="title">
        <a-input v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item label="上级菜单">
        <span class="margin_right10" v-show="selectTree">{{selectTreeLable}}</span>
        <a-button type="primary" @click="() => (isShowModal = true)">选择上级菜单</a-button>
        <a-modal v-model="isShowModal" title="选择上级菜单" cancelText="取消" okText="确定" centered @ok="() => (isShowModal = false)">
          <a-tree :tree-data="treeData" @select="onSelect" />
        </a-modal>
      </a-form-model-item>
      <a-form-model-item ref="url" label="跳转链接" prop="url">
        <a-input v-model="form.url" />
      </a-form-model-item>
      <a-form-model-item type="number" ref="sequence" label="排列序号" prop="sequence">
        <a-input v-model="form.sequence" />
      </a-form-model-item>
      <a-form-model-item label="是否显示">
        <a-radio-group name="radioGroup" :default-value="1" v-model="displayStatus">
          <a-radio :value="1"> 是 </a-radio>
          <a-radio :value="2"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="code" label="唯一标识key" prop="code">
        <a-input v-model="form.code" />
      </a-form-model-item>
      <a-form-model-item label="菜单功能" :wrapper-col="{ span: 20 }">
        <!-- 操作名称 -->
        <a-table :columns="columns" :data-source="tableData" :row-key="record => record.menuNo" bordered :pagination="false">
          <span slot="myIndex" slot-scope="myIndex, record, index">
            <span>{{ index + 1 }}</span>
          </span>
          <span slot="name" slot-scope="name, record">
            <a-input v-model="record.name" class="width150" />
          </span>
          <span slot="url" slot-scope="url, record">
            <a-input v-model="record.url" class="width250" />
          </span>
          <span slot="displayStatus" slot-scope="displayStatus, record, index">
            <a-switch :default-checked="record.displayStatus == 1"  @change="onChange($event, record, index)"/>
          </span>
          <span slot="code" slot-scope="code, record">
            <a-input v-model="record.code" class="width250" />
          </span>
          <span slot="remark" slot-scope="remark, record">
            <a-input v-model="record.remark" class="width250" />
          </span>
          <span slot="sort" slot-scope="sort, record">
            <a-input v-model="record.sort" class="width250" />
          </span>
          <span slot="operation" slot-scope="operation, record, index">
            <a @click="delClick(index)">删除</a>
          </span>
        </a-table>
        <a-button :disabled="isAddDisabled" type="primary" size="large" class="marginT20" @click="addFun">增加功能</a-button>
      </a-form-model-item>
    </a-form-model>
    <footer-tool-bar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
      <a-button type="primary" @click="keepClick" :disabled="isDisabled">保存</a-button>
    </footer-tool-bar>
  </div>
</template>

<script>
let key = 1;
import { message } from 'ant-design-vue';
import {MenuTreeApi,MenuUpdateApi,MenuDetailApi,MenuAddApi,} from '@/request/api/systemManage';
import FooterToolBar from '@/components/FooterToolbar';
import { baseMixin } from '@/store/app-mixin';
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  created() {
    let id = this.$route.params.id;
    this.menuNo = this.$route.params.id;
    if (id !== '0') {
      MenuDetailApi({ menuNo: id }).then(({ code, data }) => {
        if (code === 200) {
          this.form.title = data.detail.name;
          this.displayStatus = data.detail.displayStatus;
          this.form.url = data.detail.url;
          this.form.sequence = data.detail.sort;
          this.form.code = data.detail.code;
          this.tableData = data.functions;
        }
      });
    }
	  MenuTreeApi().then(({ code, data }) => {
      if (code == 200) {
        let newData = data.map((item) => {
          return JSON.parse(JSON.stringify(item).replace(/menuNo/g, 'key').replace(/name/g, 'title'));
        });
        this.treeData = newData; // 得到树形图的值
      }
    });
  },
  data() {
    return {
      menuNo:'',
      isDisabled: false, // 保存的按钮是否禁用
      isShowModal: false, // 树形模态框是否显示
      isAddDisabled: false, // 增加功能是否禁用
      selectTree: '', // 选中树形的值
      selectTreeLable: '', // 选中树形的文案
      displayStatus: 1, // 上排是否显示的值, 1 显示, 2 不显示
      form: { title: '', url: '', sequence: '', code: '' }, // 表单的值
      rules: {
        title: [
          {
            required: true,
            message: '输入的内容不能为空',
            trigger: 'blur',
          },
          {
            max: 32,
            message: '最长为32个',
            trigger: 'blur',
          },
        ],
        url: [
          {
            required: true,
            message: '输入的内容不能为空',
            trigger: 'blur',
          },
          {
            max: 32,
            message: '最长为32个',
            trigger: 'blur',
          },
        ],
        sequence: [
          {
            required: true,
            message: '输入的内容不能为空',
            trigger: 'blur',
          },
          {
            max: 32,
            message: '最长为32个',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '输入的内容不能为空',
            trigger: 'blur',
          },
        ],
      },
      treeData: [],
      //表格数据
      columns: [
        {
          title: '序号',
          dataIndex: 'myIndex',
          key: 'myIndex',
          scopedSlots: { customRender: 'myIndex' },
        },
        {
          title: '操作名称',
          dataIndex: 'name',
          key: 'name',
          width: '20%',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '跳转链接',
          dataIndex: 'url',
          key: 'url',
          width: '20%',
          scopedSlots: { customRender: 'url' },
        },
        {
          title: '是否显示',
          dataIndex: 'displayStatus',
          key: 'displayStatus',
          width: '7%',
          scopedSlots: { customRender: 'displayStatus' },
        },
        {
          title: '唯一标识key',
          dataIndex: 'code',
          key: 'code',
          width: '20%',
          scopedSlots: { customRender: 'code' },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          width: '15%',
          scopedSlots: { customRender: 'remark' },
        },
        {
          title: '排序',
          dataIndex: 'sort',
          key: 'sort',
          width: '8%',
          scopedSlots: { customRender: 'sort' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableData: [],
    };
  },
  methods: {
    // 点击树形的回调
    onSelect(selectedKeys, info) {
      this.selectTree = selectedKeys[0];
      this.selectTreeLable = info.node.title; // 获取对应 id 的文字
    },
    // 删除的按钮
    delClick(index) {
      this.tableData.splice(index, 1);
    },
    // 点击增加功能的按钮
    addFun() {
      this.isAddDisabled = true;
      setTimeout(() => {
        this.isAddDisabled = false;
      }, 3000);
      let key1 = key++;
      this.tableData.push({
        key1,
        name: '',
        url: '',
        displayStatus: 1,
        code: '',
        remark: '',
        sort: 0,
        operation: '删除',
      });
    },
    // 表格是否显示的回调
    onChange (val, record, index) {
        this.tableData[index].displayStatus = record.displayStatus == 1 ? 2 : 1;
    },
    // 保存的按钮
    keepClick() {
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 2000);
      let id = this.$route.params.id;
      if (id == '0') {
        MenuAddApi({
          name: this.form.title, //名称
          url: this.form.url, //链接
          parentNo: this.selectTree, //父级编号
          displayStatus: this.displayStatus, //显示状态：1正常，2隐藏
          sort: this.form.sequence*1, //排序值
          code: this.form.code, //key值
          functions: this.tableData, //子级菜单
        }).then(({ code, data }) => {
          if (code == 200) {
            message.info('新增成功');
            this.$router.push('/systemManage/menuManage');
          }
        });
      } else {
        MenuUpdateApi({
          menuNo:this.menuNo,
          name: this.form.title, //名称
          url: this.form.url, //链接
          parentNo: this.selectTree, //父级编号
          displayStatus: this.displayStatus, //显示状态：1正常，2隐藏
          sort: this.form.sequence*1, //排序值
          code: this.form.code, //key值
          functions:this.tableData, //子级菜单
        }).then(({ code }) => {
          if (code == 200) {
            message.info('保存成功');
            this.$router.push('/systemManage/menuManage');
          }
        });
      }
    },
  },
};
</script>
 
<style lang="less" scoped>
</style>