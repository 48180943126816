// 系统管理相关请求
import request from '@/request/request'
// 请求树形数据的接口
export const MenuTreeApi = () => request.post(`/agent/admin/menu/menulist`);

// 新增菜单接口
export const MenuAddApi = (params) => request.post(`/agent/admin/menu/addmenu`, params);
// 删除菜单接口
export const MenuDeleteApi = (params) => request.post(`/agent/admin/menu/deletemenu`,params);
// 更新菜单接口
export const MenuUpdateApi = (params) => request.post(`/agent/admin/menu/editmenu`, params);
// 菜单详情接口
export const MenuDetailApi = (params) => request.post(`/agent/admin/menu/menuinfo`, params);